.main-container{
   padding: 0 20px;
   max-width: 100%;
   overflow-x: hidden;
   @media (min-width: 768px) {
      padding: 0 40px;
   };
   @media (min-width: 1280px) {
      padding: 0 80px;
   }
}

